import React, { Component } from 'react'
import programmingLanguages from '../../Data/programmingLanguages';

import CommaList from '../../Components/CommaList';

export default class ResumePL extends Component {
    render() {
        return (
            <div>
                <div className='leftHeader'>Programming Langauges</div>
                <CommaList inputList={programmingLanguages}></CommaList>
            </div>
        )
    }
}
