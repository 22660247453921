import React from 'react';
import p5 from 'p5';

class DrawTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.myRef = React.createRef();
    }

    Sketch = (p) => {

        var boundX = 125;
        var boundY = 150;
        var travelDist = 100;
        var cPosX = boundX;
        var cPosY = boundY;
        var incX = 2;
        var incY = 0;

        p.setup = () => {
            p.createCanvas(800, 800);
        }

        p.draw = () => {
            cPosX += incX;
            cPosY += incY;
            if(cPosX === boundX+travelDist || cPosX === boundX) incX *= -1;
            if(cPosY === boundY+travelDist || cPosY === boundY) incY *= -1;
            
            p.ellipse(cPosX, cPosY, 80, 80);

            if(p.mouseIsPressed) {
                p.fill(0);
            } else {
                p.fill(255);
            }
            p.ellipse(p.mouseX, p.mouseY, 80,80);
        }
    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current);
    }

    render() {
        return (
            <div className="sketch" ref={this.myRef}>
                
            </div>
        );
    }
}

export default DrawTest;
