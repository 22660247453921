import React, { Component } from 'react'
import otherTechSkills from '../../Data/otherTechSkills';
import BulletList from '../../Components/BulletList';

export default class ResumeOtherTechSkills extends Component {
    render() {
        return (
            <div>
                <div className='leftHeader'>Other Technical Skills</div>
                <BulletList inputList={otherTechSkills}></BulletList>
            </div>
        )
    }
}
