var programmingLanguages = [
    "Javascript",
    "Vue",
    "React",
    "PHP",
    "SQL",
    "HTML5",
    "CSS3",
    "C#",
    "Java"
]

export default programmingLanguages;