import React from 'react';
import generalInfo from '../../Data/generalInfo';

class ResumeObjective extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className='subHeader'>Objective</div>
                <div className='infoDump'>{generalInfo.objective}</div>
            </div>
        );
    }
}

ResumeObjective.propTypes = {};

export default ResumeObjective;
