import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './App.css';

import NavBar from './Components/NavBar';
import Home from './Pages/Home';
import Resume from './Pages/Resume/Resume';
import DrawTest from './Pages/DrawTest';
import GeneticFun from './Pages/GeneticFun/GeneticFun'

function App() {
  return (
    <BrowserRouter>
      <Grid container spacing={3} className="pageContainer">
        <Grid item xs={12}>
          <NavBar></NavBar>
        </Grid>
        <Grid item>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Resume" component={Resume} />
            <Route path="/AnimationFun" component={DrawTest} />
            <Route path="/GeneticFun" component={GeneticFun} />
          </Switch>
        </Grid>
      </Grid>
    </BrowserRouter>
  );
}

export default App;
