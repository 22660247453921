var educationInfo = [
    {
        school: "University of Windsor",
        years: "2013-2014",
        degree: "Bachelor of Computer Science",
    },
    {
        school: "Brock University",
        years: "2009-2013",
        degree: "Began Computer Science degree before transferring to Windsor",
    }
]

export default educationInfo;