class Obstacle {
    constructor(xPos=0,yPos=0, oHeight=20, oWidth=20) {

        this.x = xPos;
        this.y = yPos;
        this.height = oHeight;
        this.width = oWidth;
    }

    drawObstacle(p) {
        p.fill(127);
        p.rect(this.x,this.y,this.width,this.height);
    }

    isCollision(x, y){
        if(x >= this.x && x <= this.x+this.width && y >= this.y && y <= this.y+this.height) return true;
        return false;
    }
}

export default Obstacle;