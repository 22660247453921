var experience = [
    {
        companyName: "Teksavvy Solutions Inc",
        positions: [
            {
                position: "Junior .Net Developer",
                timeSpent: "August 2017 - May 2020",
                notes: [
                    "Worked in Research & Development, primarily focusing on front-end web development using Vue and React",
                    "Designed and developed tools for customer service agents in single page applications to reduce clicks and automate redundant processes",
                    "Contributed to design, development, and maintenance of the company's main CRM application, which involved front-end design and functionality, REST APIs, and database management",
                ]
            },
            {
                position: "Technical Experience Core Member",
                timeSpent: "May 2017 - August 2017",
                notes: [
                    "Designed and developed a Chrome extension to eliminate redundancies for technical support agents",
                    "Handled escalated customer requests in a professional and courteous manner while tailoring support to their needs",
                ]
            },
            {
                position: "Case Manager",
                timeSpent: "February 2016 - May 2017",
                notes: [
                    "Provided ongoing support to customers until issues were fully resolved, while delivering updates in a timely manner",
                    "Followed and investigated ongoing issues to determine best solutions"
                ]
            },
            {
                position: "Technical Support Account Manager",
                timeSpent: "August 2015 - February 2016",
                notes: [
                    "Answered inbound calls regarding customers' technical problems, inquirings into open tickets, and general account information",
                    "Gained a detailed knowledge of vendor company interactions and policies, and submitted to tickets while providing detailed information in an exact format"
                ]
            },
            {
                position: "Technical Support Representative",
                timeSpent: "May 2015 - August 2015",
                notes: [
                    "Answered customer calls and provided support concerning Internet connections",
                    "Diagnosed and resolved problems, such as modem/router signal connection issues as well as network configuration problems"
                ]
            }
        ]
    }
];

export default experience;