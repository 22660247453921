import React, { Component } from 'react'

function neededComma(array, index){
    if(array.length-1 === index) return <></>;
    else return <>,&nbsp;</>;
}

export default class CommaList extends Component {
    render() {
        return (
            <>
                {this.props.inputList.map((item,i) => {
                    return <div className='inlineList' key={i}>{item}{neededComma(this.props.inputList,i)}</div> 
                })}
            </>
        )
    }
}