import Brain from './Brain';

class Dot {
    constructor(p) {
        this.brain = new Brain(p);
        this.position = p.createVector(p.width/2,0.9*p.height);
        this.velocity = p.createVector(0,0);
        this.acceleration = p.createVector(0.01,0);
        this.fitness = 0;
        this.dead = false;
        this.goalReached = false;

    }

    drawDot(p) {
        p.stroke(0);
        p.fill(0);
        p.ellipse(this.position.x, this.position.y, 5,5);
    }

    moveDot(p) {
        this.acceleration = this.brain.directions[this.brain.step];
        this.brain.step++;

        this.velocity.add(this.acceleration);
        this.velocity.limit(5);
        this.position.add(this.velocity);
    }

    update(p, state) {
        if(!this.dead) {
            this.moveDot(p);
            this.deathCheck(state.goal, state.Obstacles);
        }
        this.drawDot(p);
    }

    deathCheck(goal, Obstacles) {

        // If no more instructions, dot dies
        if(this.brain.step >= this.brain.directions.length) {
            this.dead = true
        }

        // if the dot hits a wall, it dies and holds it's position
        else if(this.position.x >= 800) {
            this.position.x = 800; 
            this.dead = true;
        }
        else if(this.position.x <= 0) {
            this.position.x = 0; 
            this.dead = true;
        }
        else if(this.position.y <= 0) {
            this.position.y = 0; 
            this.dead = true;
        }
        else if(this.position.y >= 800) {
            this.position.y = 800; 
            this.dead = true;
        }

        // Checking for goal reached
        else if(goal != null) { // Make sure there is a goal to shoot for
            if(this.position.x >= goal.position.x-goal.size/2 && this.position.x <= goal.position.x + goal.size/2) { // X column position of goal
              if(this.position.y >= goal.position.y-goal.size/2 && this.position.y <= goal.position.y + goal.size/2){ // Y row position of goal
                this.dead = true;
                this.goalReached = true;
              }  
            }
          }

        for(var i=0;i<Obstacles.length;i++) {
            if(Obstacles[i].isCollision(this.position.x, this.position.y)) {
                this.dead = true;
                break;
            }
        }
    }

    // Calculate the fitness of this dot
    calculateFitness(p, goal) {

        var fitness;

        if(this.goalReached) { //Calculate fitness based on how many steps it took to reach the goal
            fitness = 1.0/10.0 + 1.0/this.brain.step*this.brain.step;
        }
        else { //If the dot didn't reach the goal then calculate based on distance to goal
            var distanceToGoal = p.dist(this.position.x, this.position.y, goal.position.x, goal.position.y);
            fitness = 1.0/(distanceToGoal * distanceToGoal);
        }

        return fitness;
    }
}

export default Dot;