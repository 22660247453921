import React from 'react';
import p5 from 'p5';

import Population from './Population';
import Goal from './Goal';
import Obstacle from './Obstacle';

class GeneticFun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            goal: {},
            Obstacles: [],
            population: {},
            popSize: 500,
            obstacleNum: 200,
            p: {},
        };
        this.myRef = React.createRef();
    }

    createRandomObstacles(limit) {
        var randomObstacles = [];

        for(var i=0;i<limit;i++){

            var rX = Math.floor(Math.random() * 800);
            var rY = Math.floor(Math.random() * 800);
            
            var newObstacle = new Obstacle(rX,rY);
            randomObstacles.push(newObstacle);
        }

        return randomObstacles;
    }

    Sketch = (p) => {
        this.setState({p: p});
        this.setState({ready: true});
        this.setState({goal: new Goal(p)});
  
 

        p.setup = () => {
            
            p.createCanvas(800,800);
            var newObstacles = this.createRandomObstacles(200);
            this.setState({Obstacles: newObstacles}); 
            this.setState({population: new Population(p)});
        }

        p.draw = () => {
            p.background(255);

            for(var i=0;i<this.state.Obstacles.length;i++){
                this.state.Obstacles[i].drawObstacle(p);
            }
            this.state.goal.drawDot(p);
            this.state.population.update(p, this.state);       
        }

    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current);
    }

    render() {
        return (
            <>
                <div className="sketch" ref={this.myRef}>
                </div>
                <input type="text" onChange={this.handlePopSizeChange.bind(this)} value={this.state.popSize}></input>
                <button onClick={() => {this.resetSketch()}}>Reset! and Apply Changes</button>
                
            </>
        );
    }

    resetSketch() {
        var newObstacles = this.createRandomObstacles(200);
        this.setState({Obstacles: newObstacles}); 
        
        this.setState({population: new Population(this.state.p, this.state.popSize)});
        this.state.p.background(255);
    }

    handlePopSizeChange(e) {
        this.setState({popSize: e.target.value})
    }
}

export default GeneticFun;
