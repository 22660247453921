import React from 'react';

class BulletList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                {this.props.inputList.map((item,i) => {
                    return <div key={i}>- {item}</div> 
                })}
            </>
        );
    }
}

export default BulletList;
