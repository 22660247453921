import React, { Component } from 'react'
import volunteerInfo from '../../Data/volunteerInfo';
import BulletList from '../../Components/BulletList';

export default class ResumeVolunteerInfo extends Component {
    render() {
        return (
            <div>
                <div className='leftHeader'>Volunteer Info</div>
                {volunteerInfo.map((item,i) => {
                    return (<div key={i}>
                        <div>{item.position} | {item.years}</div>
                        <div>{item.place}</div>
                        <BulletList inputList={item.descriptionPoints}></BulletList>
                    </div>)
                })}
            </div>
        )
    }
}
