class Goal {
    constructor(p, xPos=400, yPos=100, size=15) {
        this.position = p.createVector(xPos, yPos);
        this.size = size;
    }

    drawDot(p){
        p.noStroke(0);
        p.smooth();
        p.fill('red');
        p.ellipse(this.position.x, this.position.y, this.size, this.size);
    }
}

export default Goal;