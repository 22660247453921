import Dot from './Dot';

class Population {
    constructor(p, popSize=500) {
        this.mutationRate = 0.05;
        this.fitnessSum = 0;
        this.generation = 0;
        this.popSize = popSize
        this.pop = [];
        this.allDead = false;
        this.newPopulation(p);
    }

    newPopulation(p) {
        for(var i=0;i<this.popSize;i++){
            this.pop[i] = new Dot(p);
        }
    }

    update(p, state) {
        var stillAlive = 0;

        if(this.allDead === true) return;// Don't bother updating when everything is dead.

        for(var i=0;i<this.popSize;i++) {
            this.pop[i].update(p, state);
            if(!this.pop[i].dead) stillAlive++; //Need to keep a count of how many dots are alive
        }

        if(stillAlive === 0) {
            this.allDead = true;
            this.calculateFitness(p, state.goal);
            this.newGeneration(p);
        }
    }

    calculateFitness(p, goal) {
        for(var i=0;i<this.popSize;i++){
            this.pop[i].fitness = this.pop[i].calculateFitness(p, goal);
            this.fitnessSum += this.pop[i].fitness;
        }
    }

    newGeneration(p) {
        var newPop = [];
        newPop.length = this.pop.length;

        // TODO: Maybe add immortality here



        // Select Generation Method
        // newPop = clonesAndMutants(p, newPop);
        newPop = this.crossover(p, newPop);        

        this.pop = newPop;
        this.allDead = false;
        this.generation ++;

    }

    crossover(p, newPop) {
        for(var i=0;i<newPop.length;i+=2) {

            var parent1 = this.selectParent();
            var parent2 = this.selectParent();
            var child1 = new Dot(p);
            var child2 = new Dot(p);
            var crossOverPoint = Math.floor(Math.random(child1.brain.directions.length));

            var child1BrainHalf1 = parent1.brain.directions.slice(0,crossOverPoint);
            var child1BrainHalf2 = parent2.brain.directions.slice(crossOverPoint);


            var child2BrainHalf1 = parent2.brain.directions.slice(0,crossOverPoint);
            var child2BrainHalf2 = parent1.brain.directions.slice(crossOverPoint);


            child1.brain.directions = child1BrainHalf1.concat(child1BrainHalf2);
            child2.brain.directions = child2BrainHalf1.concat(child2BrainHalf2);


            child1.brain.mutateBrain(p, this.mutationRate);
            child2.brain.mutateBrain(p, this.mutationRate);

            newPop[i] = child1;
            newPop[i+1] = child2;
        }

        return newPop;
    }

    clonesAndMutants(p, newPop) {
        for(var i=0;i<newPop.length;i++){
            var parent = this.selectParent()
            var child = new Dot(p);
            child.brain = parent.brain.cloneBrain(p);// children get the brains of their parents
            child.brain.mutateBrain(p, this.mutationRate)// they mutate over generations 
            newPop[i] = child;
        }

        return newPop;
    }

    // Selects a parent based on fitness
    // this function works by randomly choosing a value between 0 and the sum of all the fitnesses
    // then go through all the dots and add their fitness to a running sum and if that sum is greater than the random value generated that dot is chosen
    // since dots with a higher fitness function add more to the running sum then they have a higher chance of being chosen
    selectParent() {
        var rand = Math.random() * this.fitnessSum;// Some random number beween 0 and fitness Sum
        
        var runningSum = 0;

        var i = Math.floor(Math.random() * this.pop.length); //Start somewhere random in the array.
        for(i;i<this.pop.length;i++){
            runningSum += this.pop[i].fitness;
            if(runningSum > rand) {
                return this.pop[i];
            }
            if(i===this.pop.length-1)i=-1;
        }
        
    }
}

export default Population;