import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class NavBar extends Component {
    render() {
        return (
            <div className="navBar">
                <Link className="navBarLink" to="/">Home</Link>
                <Link className="navBarLink" to="/Resume">Resume</Link>
                <Link className="navBarLink" to="/AnimationFun">Animation Fun</Link>
                <Link className="navBarLink" to="/GeneticFun">Genetic Fun</Link>
            </div>
        )
    }
}
