import React from 'react';
import experience from '../../Data/experienceInfo';

class ResumeExperience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    

    render() {

        const noteStyle = {
            marginLeft: "10px"
        }
        const experienceStyle = {
            marginTop: "5px"
        }
        const positionStyle = {
            fontWeight: "Bold",
            display: "inline-block"
        }

        return (
            <div>
                <div className='subHeader'>Experience</div>
                <div className='experienceHeader'>{experience[0].companyName}</div>
                {experience[0].positions.map((item,i) => {
                    return(<div key={i} style={experienceStyle}>
                        <div style={positionStyle}>{item.position} |&nbsp;</div>
                        <div style={positionStyle}>{item.timeSpent}</div>
                        {item.notes.map((note,k) => {
                            return <div key={k} style={noteStyle}>- {note}</div>
                        })}
                    </div>)
                })}
            </div>
        );
    }
}

ResumeExperience.propTypes = {};

export default ResumeExperience;
