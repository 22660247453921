var volunteerInfo = [
    {
        position: "A/V Operator",
        place: "Calvary Christian Reformed Church, Chatham",
        years: "2013 - present",
        descriptionPoints: [
            "Responsible for running EZ Worship and PowerPoint presentations during serices",
            "During COVID-19 started recording music videos and live streaming services"
        ]
    }
]

export default volunteerInfo;