import React from 'react';

import Grid from '@material-ui/core/Grid';

import ResumeContact from './ResumeContact';
import ResumeEducation from './ResumeEducation';
import ResumePL from './ResumePL';
import ResumeOtherTechSkills from './ResumeOtherTechSkills';
import ResumeVolunteerInfo from './ResumeVolunteerInfo';

import ResumeHeader from './ResumeHeader';
import ResumeObjective from './ResumeObjective';
import ResumeExperience from './ResumeExperience';


var leftInfo = (
    <>
        <ResumeContact></ResumeContact>
        <ResumeEducation></ResumeEducation>
        <ResumePL></ResumePL>
        <ResumeOtherTechSkills></ResumeOtherTechSkills>
        <ResumeVolunteerInfo></ResumeVolunteerInfo>
    </>
)

var rightInfo = (
    <>
        <ResumeHeader></ResumeHeader>
        <ResumeObjective></ResumeObjective>
        <ResumeExperience></ResumeExperience>
    </>
)



class Resume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 800,
        };
    }



    render() {
        /*
            The mobile workaround here is a quick fix. The class names and variable names are not accurate right now.
        */
        if(this.state.isMobile) {
            return (
                <Grid container spacing={3} className="pageContainer">
                    <Grid className="mainSection" item xs={12}>
                        {rightInfo}
                    </Grid>
                    <Grid className="mainSection mainLeft" item xs={12}>
                        {leftInfo}
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container spacing={3} className="pageContainer">
                    <Grid className="mainSection mainLeft" item xs={3}>
                        {leftInfo}
                    </Grid>
                    <Grid className="mainSection" item xs={9}>
                        {rightInfo}
                    </Grid>
                </Grid>
            );  
        }      
    }
}

Resume.propTypes = {};

export default Resume;
