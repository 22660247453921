import React from 'react';
import generalInfo from '../../Data/generalInfo.js';

class ResumeHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
               <div className="mainHeader">{generalInfo.firstName} {generalInfo.lastName}</div>
                <div className="mainSubHeader">{generalInfo.position}</div> 
            </div>
        );
    }
}

ResumeHeader.propTypes = {};

export default ResumeHeader;
