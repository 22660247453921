import React, { Component } from 'react'
import contactInfo from '../../Data/contactInfo';

export default class ResumeContact extends Component {
    render() {
        return (
            <div>
                <div className='leftHeader'>Contact</div>
                <div>{contactInfo.email}</div>
            </div>
        )
    }
}
