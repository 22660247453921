import React, { Component } from 'react'
import educationInfo from '../../Data/educationInfo';

export default class ResumeEducation extends Component {
    render() {
        return (
            <div>
                <div className='leftHeader'>Education</div>
                {educationInfo.map((item,i) => {
                    return (
                        <div key={i}>
                            <div>{item.school}</div>
                            <div>{item.years}</div>
                            <div>{item.degree}</div>
                            <br/>
                        </div>
                    )
                })}
            </div>
        )
    }
}
