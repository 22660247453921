import React, { Component } from 'react'

export default class Home extends Component {
    render() {
        return (
            <div style={homeStyle}>
                Welcome to my website. <br/>
                Mostly a work in progress as a way to keep myself programming.<br />
                If you're here for the resume, click the link above :)
            </div>
        )
    }
}


var homeStyle = {
    margin: '20px',
}