import p5 from 'p5';

class Brain {
    constructor(p) {
        this.brainSize = 500;

        this.directions = [];
        this.randomizeDirections(p);
        this.step = 0;

    }

    randomizeDirections(p) {
        for (var i=0;i<this.brainSize;i++) {
            var randomAngle = p.random(2*p.PI);
            this.directions[i] = p5.Vector.fromAngle(randomAngle);
        }
    }
    
    // Possible ERROR: May need to change this to a deep clone
    cloneBrain(p) {
        var clone = new Brain(p);
        clone.directions = [...this.directions]

        return clone;
    }

    mutateBrain(p, mutationRate) {
        for(var i=0;i<this.directions.length;i++){
            var rand = Math.random();
            if(rand < mutationRate) {
                var randomAngle = p.random(2*p.PI);
                this.directions[i] = p5.Vector.fromAngle(randomAngle);
            }
        }
    }
}

export default Brain;